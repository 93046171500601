import pptxgen from 'pptxgenjs'
import * as export_helper from "./exportHelper";
import quickProtectLogo from "../assets/QuickProtect_Logo.svg"
import titleImage from "../assets/QuickProtect_title_image.png"

class PPTXBuilder{
    constructor(){
        this.pptx = new pptxgen();
        this.pptx_name = 'QuickProtect';
        this.pptx_elements = {
            ticketing: {'graphs':{}, 'tables': {}, 'data_points': {}, section_title: "Ticketing"},
            endpoint_protection: {'graphs':{}, 'tables': {}, 'data_points': {}, section_title: "Endpoint Protection"},
            device_monitoring: {'graphs':{}, 'tables': {}, 'data_points': {}, section_title: "Device Monitoring"},
            email_monitoring: {'graphs':{}, 'tables': {}, 'data_points': {}, section_title: "Email Monitoring"}
        }
    }
    set_slide(){
        this.pptx.defineSlideMaster({
            title: "quick_protect_slide",
            background: {color: "#FFFFFF"},
            objects: [
                {image: {x: 8.4, y: "3%", w: 1.4,h: 0.35, path: quickProtectLogo}},
                { text: { text: "CONFIDENTIAL", options: { x:'0%', y:'95%', w:'100%', h:'5%', align:'center', fill:'#66b85a', color:'#ffffff', fontSize: 12} } },
                { text: { text: new Date().toString().slice(4, 16), options: { x:'5%', y:'95%', w:'100%', h:'5%', align:'left', color:'#ffffff', fontSize: 8 } } }
            ],
            slideNumber: { x: "90%", y: "95%", color:'#ffffff' }
        })
        this.pptx.defineSlideMaster({
            title: "quick_title_subheader_slide",
            background: {color: "#FFFFFF"},
            objects: [
                {image: {x: 8.4, y: "3%", w: 1.4,h: 0.35, path: quickProtectLogo}},
                {text: { text: "CONFIDENTIAL", options: { x:'0%', y:'95%', w:'100%', h:'5%', align:'center', fill:'#66b85a', color:'#ffffff', fontSize: 12} } }
            ]
        })
    }
    get_name(){
        return this.pptx_name;
    }
    set_name(name){
        if(name != '' && name != null) {
            this.pptx_name = name;
        }
    }
    add_element(elementName, element, section, elementType) {
        if(elementType == 'graph')
            this.pptx_elements[section]['graphs'][elementName] = element;
        else if(elementType == 'table') {
            this.pptx_elements[section]['tables'][elementName] = element
        }
        else if(elementType == 'data')
            this.pptx_elements[section]['data_points'][elementName] = element
    }
    add_data_point_element(elementName, element, section, graph) {
        if(!this.pptx_elements[section]['graphs'][graph]) {
            this.pptx_elements[section]['graphs'][graph] = {}
        }
        if(!this.pptx_elements[section]['graphs'][graph]['data_points']) {
            this.pptx_elements[section]['graphs'][graph]['data_points'] = {}
        }
        this.pptx_elements[section]['graphs'][graph]['data_points'][elementName] = element
    }
    build(){
        this.set_slide();
        this.add_title_slide();

        for(let section in this.pptx_elements) {

            this.add_subtitle_slide(this.pptx_elements[section]['section_title']);

            // build graph elements
            for (let chart in this.pptx_elements[section]['graphs']) {
                this.add_chart_slide(chart, section);
            }
            // build table element
            for (let table in this.pptx_elements[section]['tables']) {
                this.add_table_slide(this.pptx_elements[section]['tables'][table]);
            }
        }
    }
    add_title_slide(){
        let title_slide = this.pptx.addSlide({masterName: "quick_title_subheader_slide"});
        title_slide.addImage({ x: 0, y: 0, h: '95%', w: '60%', path: titleImage});
        title_slide.addText('Managed Security Service Provider\nINSIGHTS',{x: '60%',y: '15%', w: "40%", h: 3, align: 'center', fontFace: 'Century Gothic', fontWeight: 'Bold', fontSize: 30})
    }
    add_subtitle_slide(section_title){
        let title_slide = this.pptx.addSlide({masterName: "quick_title_subheader_slide"});
        title_slide.addImage({ x: 0, y: 0, h: '95%', w: '70%', path: titleImage});
        title_slide.addText(section_title,{x: '70%' ,y: '15%', w: "30%", h:2, align: 'center', fontFace: 'Century Gothic', fontSize: 30})
    }
    add_data_point_slide(data_point){
        let data_point_slide = this.pptx.addSlide({masterName: "quick_protect_slide"});
        let data_point_text = data_point['stat_title'] + ': ' + data_point['stat_value']
        data_point_slide.addText(data_point_text,{x:1.5,y: 1.8125,w:7,h:2, align: 'center', fontFace: 'Century Gothic', fontSize: 30})
    }
    add_chart_slide(chart, section){
        let slide = this.pptx.addSlide({masterName: "quick_protect_slide"});
        let chartElement = this.pptx_elements[section]['graphs'][chart]
        slide.addText(chartElement['title'],
            {
                x:0 ,y:'5%',w:'60%',h:'10%',
                align: 'left', fontFace: 'Century Gothic', fontSize: 25, fill:'#66b85a', color:'#ffffff'
            }
        );
        slide.addImage({data: chartElement['image'], x: '45%', y: 1.56, h: 2.5, w: 4.8});

        if(chartElement['data_points']) {
            let offset = 0
            for(let data_point in chartElement['data_points']) {    
                this.add_data_point(slide, chartElement['data_points'][data_point], offset)
                offset += 10;
            }
        } else {
            slide.addText("placeholder 1", {x:'10%', y:'40%',w:'40%',h:'10%', align: 'left', fontFace: 'Century Gothic', fontSize: 15, bullet: true});
            slide.addText("placeholder 2", {x:'10%', y:'50%',w:'40%',h:'10%', align: 'left', fontFace: 'Century Gothic', fontSize: 15, bullet: true});
        }
    }
    add_data_point(slide, data_point, y_offset){
        let y_position = '40%'

        if(y_offset)
            y_position = (40+y_offset) + "%"

        let data_point_text = data_point['stat_title'] + ': ' + data_point['stat_value']
        slide.addText(data_point_text, {x:'10%', y: y_position ,w:'40%',h:'10%', align: 'left', fontFace: 'Century Gothic', fontSize: 15, bullet: true});
    }
    add_table_slide(table){
        let slide = this.pptx.addSlide({masterName: "quick_protect_slide"});
        let data_head = export_helper.build_table_header(table['content']['header'])
        let data_body = export_helper.build_table_body(table['content']['data'], data_head);

        slide.addText(table['title'],
            {
                x:0, y:'5%',w:'60%',h:'10%',
                align: 'left', fontFace: 'Century Gothic', fontSize: 25, fill:'#66b85a', color:'#ffffff'
            }
        );

        slide.addTable([data_head],{x:'5%',y:'30%',w:'90%', h:'10%', fontFace: 'Century Gothic', fontSize: 14, fill:'#66b85a', color:'#ffffff', border:{pt: "1", color: '#000000'}});
        slide.addTable(data_body,{x:'5%',y:'40%',w:'90%', fontFace: 'Century Gothic', fontSize: 10, border:{pt: "1", color: '#000000'}});
    }
    save(){
        this.pptx.writeFile(this.pptx_name);
    }
    formatNumber(number){
        return number.toLocaleString();
    }
}
export default PPTXBuilder