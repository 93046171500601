<template>
    <v-container>
        <div v-if="showAlert">
            <v-alert
                class="v-alert"
                v-model="showAlert"
                border="left"
                close-text="Close Alert"
                dismissible
                :type="alertType"
            >
                {{ alertMsg }}
            </v-alert>
        </div>
        <v-row class="ma-0 pa-0">
            <v-col cols="12">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="page-title">Device Monitoring</span>
                        <a class="page-link" href="https://auth.datto.com/login" target="_blank">
                            Datto RMM
                            <v-icon color="#66B85A">mdi-open-in-new</v-icon>
                        </a>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">mdi-clock-outline</v-icon>
                            </template>
                            <TooltipDescription title="Data Last Updated." desc_2="10/17/2024 1:31AM"></TooltipDescription>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="2" align="center">
                <v-row>
                    <v-col cols="12">
                        <MainPageStat statistic_title_name="Offline Devices" :value="offline_devices"></MainPageStat>
                    </v-col>
                    <v-col cols="12">
                        <MainPageStat statistic_title_name="Total Devices" :value="total_devices"></MainPageStat>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5" v-if="charts_config">
                <BaseChart
                    :statistic_title_name="charts_config['charts']['AntivirusStatus']['chart_title']"
                    :value="charts_config['charts']['AntivirusStatus']['chart_value']"
                    :chart_type="charts_config['charts']['AntivirusStatus']['chart_type']"
                >
                </BaseChart>
            </v-col>
            <v-col cols="5" class="ma-0 pa-6" style="height: 260px; overflow-y: hidden;" v-else>
                <v-skeleton-loader type="image,image"></v-skeleton-loader>
            </v-col>
            <v-col cols="5" v-if="charts_config">
                <BaseChart
                    :statistic_title_name="charts_config['charts']['OfflineDevicesByType']['chart_title']"
                    :value="charts_config['charts']['OfflineDevicesByType']['chart_value']"
                    :chart_type="charts_config['charts']['OfflineDevicesByType']['chart_type']"
                >
                </BaseChart>
            </v-col>
            <v-col cols="5" class="ma-0 pa-6" style="height: 260px; overflow-y: hidden;" v-else>
                <v-skeleton-loader type="image,image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" align="center">
                <StatList statistic_title_name="Device Offline for > 24hrs" :value="device_list"></StatList>
            </v-col>
            <!-- <v-col cols="5" v-else>
                <v-skeleton-loader v-bind="attrs" type="card"></v-skeleton-loader>
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
    import MainPageStat from '../StatisticComponents/MainPageStat';   
    import StatList from '../StatisticComponents/StatisticList.vue';
    import BaseChart from '../StatisticComponents/Charts/BaseChart.vue';
    import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';
    import api_caller from "@/javascript/chart_config_retrieval.js";
    import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";

    export default {
        name: 'DeviceMonitoring',
        props: [
        ],
        data: () => ({
            alertMsg: null,
            alertType: null,
            showAlert: false,

            raw_data: null,
            device_list: [],
            total_devices: null,
            offline_devices: null,
            antivirus_status: null,
            offline_device_types: null,
            charts_config: null
        }),
        components: {
            MainPageStat,
            StatList,
            BaseChart,
            TooltipDescription
        },
        mounted(){
            this.getChartConfig();
        },
        methods: {
            getChartConfig: async function () {
                const token = await this.$auth.getTokenSilently();
                let charts_config = await api_caller
                    .get_device_monitoring_charts_config(token)
                    .then((resp) => {
                        if (resp["success"]) {
                            return resp["data"];
                        } else {
                            return false;
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });

                if (charts_config) {
                    this.charts_config = charts_config;

                    this.device_list = this.charts_config['data']['DevicesOffline'];
                    this.total_devices = this.charts_config['data']['TotalDevices']['stat_value'];
                    this.offline_devices = this.charts_config['data']['OfflineDevices']['stat_value'];
                    this.antivirus_status = this.charts_config['data']['AntivirusStatus'];
                    this.offline_device_types = this.charts_config['data']['OfflineDevicesByType'];
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            },
            triggerAlert: function (type, message) {
                this.alertType = type ? type : "info";
                this.alertMsg = message;
                this.showAlert = true;
            },
        }
    }
</script>

<style scoped>
    .page-title{
        font-family: "Gothic Bold" !important;
        font-size: 25pt !important;
    }
    .page-link{
        font-family: "Gothic" !important;
        font-size: 15pt !important;
        margin-left: 10px;
        color: #66B85A;
    }
</style>