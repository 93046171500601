<template>
    <v-container>
        <v-row class="ma-0 pa-0">
            <v-col cols="12" align="center">
                <v-row class="ma-0 pa-0" justify="center">
                    <v-col cols="auto" align="right" class="ma-0 pa-0 py-10 mx-3">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <v-icon :color="current_qp_package == 'bronze' ? '#CD7F32' : '#2E2E2E'" large @click="current_qp_package='bronze'">mdi-podium-bronze</v-icon>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <span class="tier_title">Bronze</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto" align="center" class="ma-0 pa-3 mx-3">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <v-icon large :color="current_qp_package == 'gold' ? '#D4AF37' : '#2E2E2E'" @click="current_qp_package='gold'">mdi-podium-gold</v-icon>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <span class="tier_title">Gold</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto" align="left" class="ma-0 pa-0 py-10 mx-3">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <v-icon large :color="current_qp_package == 'silver' ? '#C0C0C0' : '#2E2E2E'" @click="current_qp_package='silver'">mdi-podium-silver</v-icon>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <span class="tier_title">Silver</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row class="ma-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="my-0 py-0">
                        <v-row>
                            <v-col cols="6" align="center">
                                <span class="question_title">No</span>
                            </v-col>
                            <v-col cols="6" align="center">
                                <span class="question_title">Yes</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" v-for="question in question_list" :key="question.id">
                <QuestionaireEntry :question='question.text' :checked='question.checked' v-on:update_score="update_score"></QuestionaireEntry>
            </v-col>
            <v-col cols="12">
                <v-row class="ma-0 pa-0" justify="space-around">
                    <v-col cols="auto" align="center" class="score">
                        <span class="question_score">{{ overall_score }} / 10</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import QuestionaireEntry from '../StatisticComponents/QuestionaireEntry.vue';
    import questionaire_input from "@/input_data/questionaire_input"
    import questionaire_answers_gold from "@/input_data/questionaire_answers_gold";
    import questionaire_answers_silver from "@/input_data/questionaire_answers_silver";
    import questionaire_answers_bronze from "@/input_data/questionaire_answers_bronze";
    export default {
        name: 'QuestionaireList',
        props: [
        ],
        components: {
            QuestionaireEntry
        },
        data: () => ({
            question_list: null,
            current_qp_package: 'bronze',
            overall_score: 0
        }),
        mounted() {
            this.current_qp_package = 'gold';
        },
        watch: {
            current_qp_package: function(){
                let questionaire_answer = null;
                if(this.current_qp_package === 'bronze'){
                    questionaire_answer = questionaire_answers_bronze;
                }
                else if(this.current_qp_package === 'silver'){
                    questionaire_answer = questionaire_answers_silver;
                }
                else{
                    questionaire_answer = questionaire_answers_gold;
                }
                this.question_list = null;
                let score = 0
                let temp_list = [...questionaire_input["questionaire_list"]]
                temp_list.forEach((question, idx) => {
                    question['checked'] = questionaire_answer["questionaire_list"][idx]['checked']
                    if(question['checked']) {
                        score += 1
                    }
                });
                this.overall_score = score;
                this.question_list = temp_list;
            }
        }, 
        methods: {
            update_score(type) {
                if(type == 'incr') {
                    this.overall_score +=1
                } else if (type == 'desc') {
                    this.overall_score -=1
                }
            }
        }
    }
</script>

<style scoped>
    .question_score{
        font-family: "Gothic Bold";
        font-size: 20pt;
    }
    .question_title{
        font-family: "Gothic Bold";
        font-size: 12pt;
    }
    .tier_title{
        font-family: "Gothic Bold";
        font-size: 10pt;
    }
</style>