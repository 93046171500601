<template>
    <v-row class="ma-0 pa-0">
        <v-col cols="10" align="left">
            <span class="question">{{ question }}</span>
        </v-col>
        <v-col cols="2" align="center">
            <v-row>
                <v-col cols="6" align="center">
                    <v-simple-checkbox v-model="checkbox_no" @input="on_input_change('cb_no', $event)" light color="secondary" :on-icon="'mdi-file-excel-box'"/>
                </v-col>
                <v-col cols="6" align="center">
                    <v-simple-checkbox v-model="checkbox_yes" @input="on_input_change('cb_yes', $event)" light color="secondary"/>
                </v-col>
            </v-row>
            
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'QuestionaireEntry',
        props: [
            "question",
            "checked"
        ],
        data: () => ({
            checkbox_no: false,
            checkbox_yes: false
        }),
        mounted() {
            this.checkbox_no = this.checked ? !this.checked : false,
            this.checkbox_yes = this.checked ? this.checked : false
        },
        methods: {
            on_input_change: function(box_id, event) {
                if(box_id == "cb_no") {
                    if(event) {
                        if(this.checkbox_yes) {
                            this.$emit('update_score', "desc")
                        }
                        this.checkbox_yes = false;
                    }
                } else {
                    if(event) {
                        this.$emit('update_score', "incr")
                    } else {
                        this.$emit('update_score', "desc")
                    }
                    this.checkbox_no = false;
                }
            }
        },
        watch: {
            checked: function(){
                if(this.checked){
                    this.checkbox_no = this.checked ? !this.checked : false,
                    this.checkbox_yes = this.checked ? this.checked : false
                }
                else{
                    this.checkbox_no = true;
                    this.checkbox_yes = false;
                }
            }
        }
    }
</script>

<style scoped>
    .question{
        font-family: "Gothic";
        font-size: 12pt;
    }
</style>