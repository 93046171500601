<template>
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="auto">
            <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ma-0 pa-0" cols="auto">
            <v-btn icon>
                <v-icon v-if="link" color="secondary" @click="open_further_details">mdi-magnify-expand</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" style="height: 200px">
            <GChart
                type="ColumnChart"
                :options="options"
                :data="data"></GChart>
        </v-col>
    </v-row>
</template>

<script>
    import { GChart } from "vue-google-charts/legacy";
    export default {
        name: 'StackedBarChart',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        components: {
            GChart
        },
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            data: [
                ['Threat','Quantity','Quantity B','Quantity C'],
                ['Threat 1', 50, 40, 30],
                ['Threat 2', 40, 10, 15],
                ['Threat 3', 60, 30, 10],
                ['Threat 4', 55, 20, 5],
                ['Threat 5', 60, 70, 5],
            ],
            options: {
                "legend": { "position": "none" },
                "chartArea": {"margin":"0 0", "top": "2%", "bottom": "30%", "right": 0, "left": "13%"},
                "isStacked": true,
                "vAxis": {
                    "minValue": 0,
                    "ticks": [0, 0.2, 0.4, 0.6, 0.8, 1]
                },
                "tooltip": {
                    "trigger": "none"
                },
                "colors": [
                    "#66B85A", 
                    "#FF6F61",  
                    "#6A5ACD", 
                    "#FFA07A",  
                    "#20B2AA",  
                    "#FFD700",  
                    "#4682B4",  
                    "#FF6347",  
                    "#8A2BE2",  
                    "#FF4500"  
                ],
                "fontName": "Gothic"
            }
        }),
        watch: {
            value: function(){
                if(this.value != null){
                    this.data = this.value;
                }
            }
        },
        mounted(){
            if(this.value != null){
                this.data = this.value;
            }
        },
        methods: {
            open_further_details: function(){
                if(this.link){
                    this.$router.replace(this.link);
                }
            }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;

        display: block;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>