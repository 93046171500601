<template>
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="11">
            <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-col class="ma-0 pa-0" cols="1">
            <v-btn icon>
                <v-icon v-if="link" color="secondary">mdi-magnify-expand</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-0" style="height: 200px">
            <GChart
                type="BarChart"
                :options="options_b"
                :data="data_side_b"></GChart>
        </v-col>
        <v-col cols="6" style="height: 200px" class="pa-0">
            <GChart
                type="BarChart"
                :options="options"
                :data="data_side_a"></GChart>
        </v-col>
    </v-row>
</template>

<script>
    import { GChart } from "vue-google-charts/legacy";
    export default {
        name: 'TwoSidedBarChart',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        components: {
            GChart
        },
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            data_side_a: [
                ['Threat','Quantity', 'Quantity B','Quantity', 'Quantity B'],
                ['Threat 1', 50, 50, 50, 50],
                ['Threat 2', 40, 60, 50, 50],
                ['Threat 3', 60, 70, 50, 50],
                ['Threat 4', 55, 80, 50, 50],
                ['Threat 5', 60, 30, 50, 50],
            ],
            data_side_b: [
                ['Threat','Quantity', 'Quantity B', 'Quantity', 'Quantity B'],
                ['Threat 1', -50, -50, -50, -50],
                ['Threat 2', -40, -60, -50, -50],
                ['Threat 3', -60, -70, -50, -50],
                ['Threat 4', -55, -80, -50, -50],
                ['Threat 5', -60, -30, -50, -50],
            ],
            options: {
                "legend": { "position": "none" },
                "chartArea": {"margin":"0 0 0 0", "top": "0", "bottom": "0", "right": 0, "left": "0"},
                "isStacked": "true",
                "vAxis": {
                    "minValue": 0,
                    "ticks": []
                },
                "tooltip": {
                    "trigger": "none"
                },
                "colors": [
                    "#66B85A", 
                    "#FF6F61",  
                    "#6A5ACD", 
                    "#FFA07A",  
                    "#20B2AA",  
                    "#FFD700",  
                    "#4682B4",  
                    "#FF6347",  
                    "#8A2BE2",  
                    "#FF4500"  
                ],
                "fontName": "Gothic"
            },
            options_b: {
                "legend": { "position": "none" },
                "chartArea": {"margin":"0 0 0 0", "top": "0", "bottom": "0", "right": "0", "left": 0},
                "isStacked": "true",
                "vAxis": {
                    "ticks": []
                },
                "tooltip": {
                    "trigger": "none"
                },
                "colors": [
                    "#66B85A", 
                    "#FF6F61",  
                    "#6A5ACD", 
                    "#FFA07A",  
                    "#20B2AA",  
                    "#FFD700",  
                    "#4682B4",  
                    "#FF6347",  
                    "#8A2BE2",  
                    "#FF4500"  
                ],
                "fontName": "Gothic"
            }
        })
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;

        display: block;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>