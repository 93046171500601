<template>
  <v-container>
    <div v-if="showAlert">
      <v-alert
        v-model="showAlert"
        border="left"
        close-text="Close Alert"
        dismissible
        :type="alertType"
      >
        {{ alertMsg }}
      </v-alert>
    </div>

    <!-- Main Statistics -->
    <v-row class="ma-0 pa-0" justify="space-around">
      <v-col cols="auto" class="ma-0 pa-3">
        <MainPageStat
          :statistic_title_name="cyber_security_score['title']"
          :value="cyber_security_score['value']"
          :change="cyber_security_score['change']"
          emphasized="true"
        ></MainPageStat>
      </v-col>
      <v-col cols="auto" class="ma-0 pa-3">
        <MainPageStat
          :statistic_title_name="cyber_insurance_score['title']"
          :value="cyber_insurance_score['value']"
          emphasized="true"
        ></MainPageStat>
      </v-col>
    </v-row>
    <!-- Secondary Statistics -->
    <v-row
      class="ma-0 pa-0"
      justify="space-around"
      v-if="charts_config && userPreferences"
    >
      <v-col
        cols="3"
        class="ma-0 pa-3"
        v-for="stat in userPreferences['data_points_stat']"
        :key="stat"
      >
        <MainPageStat
          :statistic_title_name="charts_config['data'][stat]['stat_title']"
          :value="charts_config['data'][stat]['stat_value']"
          :change="charts_config['data'][stat]['stat_change']"
        ></MainPageStat>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="space-around" v-else>
      <v-col cols="3" v-for="idx in [1, 2, 3, 4]" :key="idx">
        <MainPageStat statistic_title_name="" :value="null" :change="null"></MainPageStat>
      </v-col>
    </v-row>
    <v-row
      class="ma-0 pa-0"
      justify="space-around"
      v-if="charts_config && userPreferences"
    >
      <v-col cols="12">
        <v-row class="ma-0 pa-0">
          <v-col cols="6" v-for="graph in userPreferences['graphs_stat']" :key="graph">
            <BaseChart
              :statistic_title_name="charts_config['charts'][graph]['chart_title']"
              :link="charts_config['charts'][graph]['chart_link']"
              :value="charts_config['charts'][graph]['chart_value']"
              :chart_type="charts_config['charts'][graph]['chart_type']"
            >
            </BaseChart>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="charts_config">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <BaseChart
              :statistic_title_name="
                charts_config['charts']['ScoreOverview']['chart_title']
              "
              :link="charts_config['charts']['ScoreOverview']['chart_link']"
              :value="charts_config['charts']['ScoreOverview']['chart_value']"
              :chart_type="charts_config['charts']['ScoreOverview']['chart_type']"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="space-around" v-else>
      <v-col
        cols="6"
        v-for="idx in [1, 2, 3, 4]"
        :key="idx"
        style="height: 288px; overflow-y: hidden"
      >
        <v-skeleton-loader type="image, image"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" style="height: 412px; overflow-y: hidden">
        <v-skeleton-loader type="image, image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainPageStat from "./StatisticComponents/MainPageStat";
import BaseChart from "./StatisticComponents/Charts/BaseChart.vue";
import api_caller from "@/javascript/chart_config_retrieval.js";
import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";

export default {
  name: "BasePage",

  data: () => ({
    home_page_raw: null,
    cyber_security_score: {
      title: "QuickProtect Score",
      value: null,
      change: null,
    },
    cyber_insurance_score: {
      title: "Cyber Insurance Score",
      value: null,
    },
    charts_config: null,
    alertMsg: null,
    alertType: null,
    showAlert: false,
    userPreferences: null,

    quick_protect_score: null,
  }),
  components: {
    MainPageStat,
    BaseChart,
  },

  mounted() {
    this.getUserPreferences();
    this.getChartConfig();
  },
  methods: {
    load_stat_value: function (dataset, stat_path) {
      let tmp = dataset;
      for (let path_val of stat_path) {
        tmp = tmp[path_val];
      }
      return tmp;
    },

    getChartConfig: async function () {
      const token = await this.$auth.getTokenSilently();
      let charts_config = await api_caller
        .get_home_charts_config(token)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
          return false;
        });

      if (charts_config) {
        this.charts_config = charts_config;

        this.cyber_security_score = this.charts_config["data"]["ScoreOverview"][
          "cyber_security_score"
        ];
        this.cyber_insurance_score = this.charts_config["data"]["ScoreOverview"][
          "cyber_insurance_score"
        ];
      } else {
        this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },
    getUserPreferences: async function () {
      let token = await this.$auth.getTokenSilently();
      let userPreferences = await api_caller
        .get_homepage_user_preferences(token)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (userPreferences) {
        this.userPreferences = userPreferences;
      }
    },

    triggerAlert: function (type, message) {
      this.alertType = type ? type : "info";
      this.alertMsg = message;
      this.showAlert = true;
    },
  },
};
</script>

<style scoped>
.tab_title {
  font-family: "Gothic Bold";
  font-size: 0.8em;
  color: black;
}
</style>
