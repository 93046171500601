<template>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="auto">
          <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0" cols="auto">
          <v-btn icon>
              <v-icon v-if="link" color="secondary">mdi-magnify-expand</v-icon>
          </v-btn>
      </v-col>
      <v-col cols="12" >
        <v-data-table :headers="header" :items="items" align="left" >
              <template v-for="(h,index) in header" v-slot:[`header.${h.value}`]>
                <span class="headerContent" :key="index">{{ h.text }}</span>
              </template>
              <template v-for="col in header" v-slot:[`item.${col.value}`]="{ item }">
                <span class="tableContent" :key="col.value">{{ item[col.value] }}</span>
              </template>

              <!-- format all plain numeric columns -->
              <!-- <template v-for="numCol in headers" v-slot:[`item.${numCol.value}`]="{ item }">
                <div v-if="numericColumns.includes(numCol.value) || currencyColumns.includes(numCol.value) || percentageColumns.includes(numCol.value)" :key="numCol.value" :style="{backgroundColor: update_color(item)}" class="numericCellFlighting ma-0 pa-1 px-2">
                  <span v-if="numericColumns.includes(numCol.value)">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{ minimumFractionDigits: 0, maximumFractionDigits: 0}) : "n/a" }}
                  </span>
                  <span v-else-if="currencyColumns.includes(numCol.value)">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "currency", currency: "CAD", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else-if="percentageColumns.includes(numCol.value) && item[numCol.value] > 0" style="color:green">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else-if="percentageColumns.includes(numCol.value) && item[numCol.value] < 0" style="color:red">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else>
                    N/A
                  </span>
                </div>
                <div v-else :key="numCol.value" :style="{backgroundColor: update_color(item)}" class="stringCellFlighting ma-0 pa-1 px-2">
                  <span>
                    {{level_val(item, numCol.value)}}
                  </span>
                </div>
              </template> -->
        </v-data-table>
      </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'StatisticsTable',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        mounted(){
          if(this.value) {
            this.header = this.value['header'];
            this.items = this.value['data'];
          }
        },
        data: () => ({
            header: [],
            items: [],
        }),
        watch: {
          value: function(){
            this.header = this.value['header'];
            this.items = this.value['data'];
          }
        },
        methods: {
        }
    }
</script>

<style>
    .headerContent {
      font-family: "Gothic Bold" !important;
      font-size: 12pt;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      min-width: 80px;
      text-align: center;
      color: #FFFFFF;
    }
    .v-data-table__wrapper > table > thead > tr > th {
      background-color: #4C8D44;
    }
    .tableContent {
      font-family: "Gothic" !important;
      font-size: 10pt;
      display: inline-block;
      min-width: 10px;
      max-width: 250px;
      text-align: center;
      color: #000000;
    }
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;
    }
    .statistic_title{
        font-family: "Gothic Bold";
        font-size: 14pt;
    }
    .statistic_value{
        font-family: "Gothic";
        font-size: 12pt;
    }
    .list_header{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
    }
    .date-box-light {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 88px;
      background-color: #85D97C;
      color: white;
      /* border-radius: 50%; */
      font-size: 18px;
    }
    .date-box-dark {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 88px;
      background-color: #4C8D44;
      color: white;
      /* border-radius: 50%; */
      font-size: 18px;
    }

    .day {
      font-size: 22px;
      font-weight: bold;
    }

    .month {
      font-size: 18px;
    }
</style>